import {
  XYPlot,
  XAxis,
  YAxis,
  VerticalBarSeries,
} from 'react-vis';

const Graph = ({
  data,
}: {
  data: {
    x: number;
    y: number;
  }[];
}) => (
  <>
    <XYPlot margin={{ left: 100 }} width={600} height={600}>
      <VerticalBarSeries barWidth={0.8} data={data} />
      <YAxis tickFormat={(tick) => `${tick / 1000}万`} />
      <XAxis />
    </XYPlot>
  </>
);

export default Graph;
