import { ChangeEvent, useEffect, useState } from 'react';
import Graph from '../../components/Graph';
import calcReserve from '../../util/calc';

const Index = () => {
  const [state, setState] = useState({
    initialFund: 0,
    reserveAmount: 0,
    annualRate: 0,
  });
  const [graphState, setGraphState] = useState<
    {
      x: number;
      y: number;
    }[]
  >([]);
  useEffect(() => {
    const result = calcReserve(
      state.initialFund,
      state.reserveAmount,
      state.annualRate
    );

    setGraphState(result);
  }, [state]);
  const inputChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setState({ ...state, [event.target.id]: parseInt(event.target.value) });
  };
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Graph data={graphState} />
      年率:{' '}
      <input
        type="text"
        id="annualRate"
        value={state.annualRate}
        onChange={inputChange}
      />
      %
      <br />
      初期投資金:{' '}
      <input
        type="text"
        id="initialFund"
        value={state.initialFund}
        onChange={inputChange}
      />
      円
      <br />
      1年での積立額
      <input
        type="text"
        id="reserveAmount"
        value={state.reserveAmount}
        onChange={inputChange}
      />
      円
      <br />
    </div>
  );
};

export default Index;
