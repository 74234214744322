const calcReserve = (
  initialFund: number,
  reserveAmount: number,
  annualRate: number
): {
  x: number;
  y: number;
}[] => {
  const result: {
    x: number;
    y: number;
  }[] = [];

  for (let index = 1; index < 60; index++) {
    result.push({ x: index, y: 0 });
  }

  result.map((item, index, array) => {
    let value: number =
      index == 0
        ? initialFund
        : currentFund(array[index - 1].y, annualRate, reserveAmount);

    array[index] = { x: item.x, y: value };
  });

  return result;
};

const currentFund = (prev: number, rate: number, reserveAmount: number) =>
  Math.floor((prev * rate) / 100 + prev + reserveAmount);

export default calcReserve;
